const globalMethod = {
  install(Vue) {
    Vue.prototype.$open = function (url) {
      this.$router.push(url)
    }
    Vue.prototype.$openUrl = function(url) {
      window.location.href = url
    }

  }
}

export default globalMethod
